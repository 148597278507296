import { getExperienceData, getGlobalCMSData } from '@/utils/cms'

const Main = () => {
  return <></>
}

export async function getStaticProps(context) {
  try {
    const pageData = await getGlobalCMSData(context)
    const experienceData = await getExperienceData(context)
    const props = { pageData, experienceData }
    return {
      notFound: !pageData,
      props: props,
      revalidate: 2,
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error', e.config.url)
    return {
      props: { error: e.message },
      revalidate: 2,
    }
  }
}

Main.experience = true
Main.uniqueId = 'Main'
export default Main
